<template>
  <div class="bg-white d-flex flex-column">
    <span :class="`${isMobile ? 'mt-10 mx-5 font-25' : 'mt-20 mx-20 font-40'} center font-bold`">{{ $t('become_an_organizer_title') }}</span>
    <span :class="`${isMobile ? 'mt-5 mx-5 font-12' : 'mt-10 mx-20 font-15'}`">{{ $t('become_an_organizer_desc') }}</span>
    <div class="mt-15 position-relative" v-if="isWide">
      <img class="w-100" :src="assets.become_organizer_bg1" />
      <div class="w-100 position-absolute top-0 left-0 d-flex justify-content-between vertical-center">
        <div class="w-50">
          &nbsp;
        </div>
        <div class="w-50">
          <div class="mt-40 mx-20 box-pink-light d-flex flex-column">
            <div class="my-5 vertical-center">
              <img class="ml-10 mr-5" :src="assets.effortless_ticketing" :width="60" />
              <div class="d-flex flex-column">
                <span class="font-bold font-15">{{ $t('effortless_ticketing') }}</span>
                <span class="mt-3 font-12">{{ $t('effortless_ticketing_desc') }}</span>
              </div>
            </div>
            <div class="my-5 vertical-center">
              <img class="ml-10 mr-5" :src="assets.amplify_your_brand" :width="60" />
              <div class="d-flex flex-column">
                <span class="font-bold font-15">{{ $t('amplify_your_brand') }}</span>
                <span class="mt-3 font-12">{{ $t('amplify_your_brand_desc') }}</span>
              </div>
            </div>
            <div class="my-5 vertical-center">
              <img class="ml-10 mr-5" :src="assets.boost_your_revenue" :width="60" />
              <div class="d-flex flex-column">
                <span class="font-bold font-15">{{ $t('boost_your_revenue') }}</span>
                <span class="mt-3 font-12">{{ $t('boost_your_revenue_desc1') }}<span class="mx-1 font-12 font-bold color-pink">35%</span>{{ $t('boost_your_revenue_desc2') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img class="mt-10 w-100" :src="assets.become_organizer_bg1_mobile" v-else />
    <div class="mt-20 mx-5 box-pink-light d-flex flex-column" v-if="!isWide">
      <div class="my-5 vertical-center">
        <img class="ml-10 mr-5" :src="assets.effortless_ticketing" :width="60" />
        <div class="d-flex flex-column">
          <span class="font-bold font-15">{{ $t('effortless_ticketing') }}</span>
          <span class="mt-3 font-12">{{ $t('effortless_ticketing_desc') }}</span>
        </div>
      </div>
      <div class="my-5 vertical-center">
        <img class="ml-10 mr-5" :src="assets.amplify_your_brand" :width="60" />
        <div class="d-flex flex-column">
          <span class="font-bold font-15">{{ $t('amplify_your_brand') }}</span>
          <span class="mt-3 font-12">{{ $t('amplify_your_brand_desc') }}</span>
        </div>
      </div>
      <div class="my-5 vertical-center">
        <img class="ml-10 mr-5" :src="assets.boost_your_revenue" :width="60" />
        <div class="d-flex flex-column">
          <span class="font-bold font-15">{{ $t('boost_your_revenue') }}</span>
          <span class="mt-3 font-12">{{ $t('boost_your_revenue_desc1') }}<span class="mx-1 font-12 font-bold color-pink">20%</span>{{ $t('boost_your_revenue_desc2') }}</span>
        </div>
      </div>
    </div>
    <div :class="`w-100 ${isMobile ? 'mt-10 px-5' : 'mt-20 px-30'} d-flex flex-column`">
      <div class="w-100 box-pink-light">
        <div :class="`d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <div class="m-10 d-flex flex-column">
            <span class="font-bold font-20">{{ $t('create_your_business_account_now_for_free') }}</span>
            <span class="mt-5 font-15">{{ $t('create_your_business_account_now_for_free_desc') }}</span>
          </div>
          <v-btn class="m-5 button-normal" @click="gotoOrganizerRegister()">
            <span class="px-5">{{ $t('get_started') }}</span>
          </v-btn>
        </div>
      </div>
      <div :class="`d-flex ${isMobile ? 'mt-10 flex-column' : 'mt-20 justify-content-between vertical-center'}`">
        <div class="my-10 d-flex flex-column">
          <span class="font-bold font-20">{{ $t('all_in_one_for_your_event') }}</span>
          <span class="mt-5 font-15">{{ $t('all_in_one_for_your_event_desc') }}</span>
        </div>
        <img :src="assets.become_organizer_img1_mobile" v-if="isMobile" />
        <img :src="assets.become_organizer_img1" :width="500" v-else />
      </div>
      <div :class="`row ${isMobile ? 'my-10' : 'my-20'}`">
        <div class="mt-5 col-sm-12 col-md-6">
          <div :class="`${isMobile ? 'mx-5' : 'mr-10'} d-flex flex-column`">
            <img :src="assets.community_building" :width="60" />
            <span class="mt-5 font-bold font-20">{{ $t('community_building') }}</span>
            <span class="mt-3 font-15">{{ $t('community_building_desc') }}</span>
          </div>
        </div>
        <div class="mt-5 col-sm-12 col-md-6">
          <div :class="`${isMobile ? 'mx-5' : 'mr-10'} d-flex flex-column`">
            <img :src="assets.custom_pricing" :width="60" />
            <span class="mt-5 font-bold font-20">{{ $t('custom_pricing') }}</span>
            <span class="mt-3 font-15">{{ $t('custom_pricing_desc') }}</span>
          </div>
        </div>
        <div class="mt-5 col-sm-12 col-md-6">
          <div :class="`${isMobile ? 'mx-5' : 'mr-10'} d-flex flex-column`">
            <img :src="assets.seamless_check_in" :width="60" />
            <span class="mt-5 font-bold font-20">{{ $t('seamless_check_in') }}</span>
            <span class="mt-3 font-15">{{ $t('seamless_check_in_desc') }}</span>
          </div>
        </div>
        <div class="mt-5 col-sm-12 col-md-6">
          <div :class="`${isMobile ? 'mx-5' : 'mr-10'} d-flex flex-column`">
            <img :src="assets.real_time_dashboard" :width="60" />
            <span class="mt-5 font-bold font-20">{{ $t('real_time_dashboard') }}</span>
            <span class="mt-3 font-15">{{ $t('real_time_dashboard_desc') }}</span>
          </div>
        </div>
        <div class="mt-5 col-sm-12 col-md-6">
          <div :class="`${isMobile ? 'mx-5' : 'mr-10'} d-flex flex-column`">
            <img :src="assets.financial_control" :width="60" />
            <span class="mt-5 font-bold font-20">{{ $t('financial_control') }}</span>
            <span class="mt-3 font-15">{{ $t('financial_control_desc') }}</span>
          </div>
        </div>
        <div class="mt-5 col-sm-12 col-md-6">
          <div :class="`${isMobile ? 'mx-5' : 'mr-10'} d-flex flex-column`">
            <img :src="assets.fast_payouts" :width="60" />
            <span class="mt-5 font-bold font-20">{{ $t('fast_payouts') }}</span>
            <span class="mt-3 font-15">{{ $t('fast_payouts_desc') }}</span>
          </div>
        </div>
        <div class="mt-5 col-sm-12 col-md-6">
          <div :class="`${isMobile ? 'mx-5' : 'mr-10'} d-flex flex-column`">
            <img :src="assets.fast_payouts" :width="60" />
            <span class="mt-5 font-bold font-20">{{ $t('customer_friendly_cash_system') }}</span>
            <span class="mt-3 font-15">{{ $t('customer_friendly_cash_system_desc') }}</span>
          </div>
        </div>
        <div class="mt-5 col-sm-12 col-md-6">
          <div :class="`${isMobile ? 'mx-5' : 'mr-10'} d-flex flex-column`">
            <img :src="assets.fast_payouts" :width="60" />
            <span class="mt-5 font-bold font-20">{{ $t('transparent_fee_individual_solution') }}</span>
            <span class="mt-3 font-15">{{ $t('transparent_fee_individual_solution_desc') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div :class="`w-100 d-flex ${isWide ? 'mt-20 px-30 justify-content-between vertical-center' : 'mt-10 px-5 flex-column'}`" style="background-color: #F9F9F9;">
      <div :class="`d-flex flex-column ${isWide ? 'mt-30 w-50 px-20' : 'mt-10 w-100'} center`">
        <span class="mt-5 font-20">{{ $t('get_it_now') }}</span>
        <span class="mt-5 font-bold font-30">{{ $t('download_the_app') }}</span>
        <div class="mt-10 d-flex justify-content-between">
          <div class="d-inline mr-10" v-if="platform === 'Desktop' || platform === 'iOS'">
            <a :href="APP_STORE_URL" target="_blank">
              <img class="store-image" :src="assets.app_store" :height="isMobile ? 40 : 60" />
            </a>
          </div>
          <div class="d-inline" v-if="platform === 'Desktop' || platform === 'Android'">
            <a :href="GOOGLE_PLAY_URL" target="_blank">
              <img class="store-image" :src="assets.google_play" :height="isMobile ? 40 : 60" />
            </a>
          </div>
        </div>
      </div>
      <div :class="isWide ? 'mt-30 w-50 px-30' : 'mt-10 w-100 px-10 center'">
        <img :src="assets.become_organizer_img2" :width="500" v-if="isWide" />
        <img class="w-100" :src="assets.become_organizer_img2" v-else />
      </div>
    </div>
  </div>
</template>


<script>
import become_organizer_bg1 from '@/assets/images/become_organizer_bg1.jpg';
import become_organizer_bg1_mobile from '@/assets/images/become_organizer_bg1_mobile.jpg';
import effortless_ticketing from '@/assets/images/effortless_ticketing.png';
import amplify_your_brand from '@/assets/images/amplify_your_brand.png';
import boost_your_revenue from '@/assets/images/boost_your_revenue.png';
import become_organizer_img1 from '@/assets/images/become_organizer_img1.jpg';
import become_organizer_img1_mobile from '@/assets/images/become_organizer_img1_mobile.jpg';
import community_building from '@/assets/images/community_building.png';
import custom_pricing from '@/assets/images/custom_pricing.png';
import seamless_check_in from '@/assets/images/seamless_check_in.png';
import real_time_dashboard from '@/assets/images/real_time_dashboard.png';
import financial_control from '@/assets/images/financial_control.png';
import fast_payouts from '@/assets/images/fast_payouts.png';
import become_organizer_img2 from '@/assets/images/become_organizer_img2.jpg';
import google_play from '@/assets/images/google_play.png';
import app_store from '@/assets/images/app_store.png';

import { getPlatform } from '../../../functions';

export default {
  name: 'BecomeOrganizer',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    isWide() {
      return this.$store.state.isWide;
    }
  },
  data() {
    return {
      assets: {
        become_organizer_bg1,
        become_organizer_bg1_mobile,
        effortless_ticketing,
        amplify_your_brand,
        boost_your_revenue,
        become_organizer_img1_mobile,
        become_organizer_img1,
        community_building,
        custom_pricing,
        seamless_check_in,
        real_time_dashboard,
        financial_control,
        fast_payouts,
        become_organizer_img2,
        google_play,
        app_store
      },
      platform: getPlatform()
    }
  },
  methods: {
    gotoOrganizerRegister() {
      localStorage.setItem('eventboxUserType', 1);
      this.$router.push(`/welcome`);
    }
  }
};
</script>